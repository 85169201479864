import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/item'));
const OrderHistoryPage = lazy(() => import('src/pages/dashboard/order-history'));
const OrdersPage = lazy(() => import('src/pages/dashboard/workspace'));
const SettingsPage = lazy(() => import('src/pages/dashboard/setting/printer'));
const TerminalSettingPage = lazy(() => import('src/pages/dashboard/setting/terminal'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'menus', element: <IndexPage /> },
      { path: 'order-history', element: <OrderHistoryPage /> },
      { path: 'workspace', element: <OrdersPage /> },
      { path: 'setting/printer', element: <SettingsPage /> },
      { path: 'setting/terminal', element: <TerminalSettingPage /> },
    ],
  },
];
