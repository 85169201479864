import merge from 'lodash/merge';
import { enUS as enUSAdapter, fr as frFRAdapter, de as deDEAdapter } from 'date-fns/locale';
// core
import { enUS as enUSCore, frFR as frFRCore, deDE as deDECore } from '@mui/material/locale';
// date-pickers
import { enUS as enUSDate, frFR as frFRDate, deDE as deDEDate } from '@mui/x-date-pickers/locales';
// data-grid
import { enUS as enUSDataGrid, frFR as frFRDataGrid, deDE as deDEDataGrid } from '@mui/x-data-grid';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: 'flagpack:fr',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: merge(deDEDate, deDEDataGrid, deDECore),
    adapterLocale: deDEAdapter,
    icon: 'flagpack:de',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
