// @mui
import Box from '@mui/material/Box';
// hooks
import { useCallback, useEffect } from 'react';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { useSnackbar } from 'src/components/snackbar';
import { getFirebaseToken, messaging } from 'src/firebase/firebaseConfig';
import { useRouter } from 'src/routes/hooks';
import { updateNotificationSettings } from 'src/api/auth';
import { useWorkspaceContext } from 'src/sections/workspace/context';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();
  const { onGetWorkspaces, onGetWorkspaceKDSs } = useWorkspaceContext();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;
  // const router = useRouter();

  const handleUpdateSettings = useCallback(
    async (firebaseToken: string) => {
      try {
        await updateNotificationSettings({
          isNotificationEnable: !!firebaseToken,
          fcmNotificationToken: firebaseToken ?? '',
        });
      } catch (error) {
        enqueueSnackbar({
          variant: 'warning',
          message: `Cannot update notification setting: ${error?.message}`,
        });
      }
    },
    [enqueueSnackbar]
  );

  const handleGetFirebaseToken = useCallback(async () => {
    try {
      const firebaseToken = await getFirebaseToken();
      if (firebaseToken) {
        handleUpdateSettings(firebaseToken);
      }
    } catch (error) {
      handleGetFirebaseToken();
    }
  }, [handleUpdateSettings]);

  const requestPermission = useCallback(async () => {
    console.log('Requesting permission...');
    const permission = await window?.Notification?.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      router.reload();
    }
  }, [router]);

  useEffect(() => {
    console.log(window.Notification?.permission);
    if (window.Notification?.permission === 'granted') {
      handleGetFirebaseToken();
    } else {
      requestPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onMessageListener = async () => {
      const messagingResolve = await messaging;
      if (messagingResolve) {
        onMessage(messagingResolve, (payload: MessagePayload) => {
          console.log('🚀 ~ onMessage ~ payload:', payload);

          onGetWorkspaces();
          onGetWorkspaceKDSs();
          enqueueSnackbar({
            variant: 'warning',
            message: payload?.notification?.body,
            autoHideDuration: 6000,
          });
        });
      }
    };

    onMessageListener();
  }, [enqueueSnackbar, onGetWorkspaces, onGetWorkspaceKDSs]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}
