// @mui
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
// hooks
import { FormDialogProps } from './types';

// ----------------------------------------------------------------------

export default function FormDialog({
  title,
  content,
  action,
  open,
  onClose,
  disableClosing,
  children,
  ...other
}: FormDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (disableClosing) return;

        onClose();
      }}
      {...other}
    >
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent dividers={other.scroll === 'paper'}>{children}</DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        {action}
      </DialogActions>
    </Dialog>
  );
}
