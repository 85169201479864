import { useEffect, useReducer, useCallback, useMemo } from 'react';
// apis
import numeral from 'numeral';
import { getRequestLogin, getUserMe, reqConfirmLogin } from 'src/api/auth';
// utils
import { convertLocale } from 'src/utils/format-number';
//
import { localStorageGetItem } from 'src/utils/storage-available';
import { AuthContext } from './auth-context';
import { STORAGE_KEY, STORAGE_KEY_FCM, setToken } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY.POS_ACCESS_TOKEN);
      const refreshToken = localStorage.getItem(STORAGE_KEY.POS_REFRESH_TOKEN);
      if (accessToken && refreshToken) {
        setToken(accessToken, refreshToken);

        const response = await getUserMe();

        const res = response.data.data;

        const locale = convertLocale(res?.branch?.country?.currency);

        numeral.locale(locale);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: res,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const requestLogin = useCallback(async () => {
    const response = await getRequestLogin();

    return response.data;
  }, []);

  // CONFIRM LOGIN
  const confirmLogin = useCallback(
    async (token: string, passcode: string) => {
      const fcm = localStorageGetItem(STORAGE_KEY_FCM);
      const data = {
        token,
        passcode,
        isNotificationEnable: !!fcm,
        notificationToken: fcm ?? '',
      };

      const response = await reqConfirmLogin(data);

      const { accessToken, refreshToken } = response.data.data;

      setToken(accessToken, refreshToken);

      initialize();
    },
    [initialize]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setToken(null);
    localStorage.removeItem(STORAGE_KEY_FCM);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      requestLogin,
      logout,
      confirmLogin,
    }),
    [requestLogin, logout, state.user, status, confirmLogin]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
