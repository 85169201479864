import { useMemo, useCallback, useReducer } from 'react';
// types
import { ActionMapType } from 'src/auth/types';
import { IOrderWorkspaceData, IOrderWorkspaceKDSData } from 'src/types/order';
//
import { getKDSs, getWorkspaces } from 'src/api/workspace';
import { WorkspaceStateType } from 'src/types/workspace';
import { WorkspaceContext } from './workspace-context';

// ----------------------------------------------------------------------

enum Types {
  WORKSPACE = 'WORKSPACE',
  KDS = 'KDS',
}

type Payload = {
  [Types.WORKSPACE]: {
    workspaces?: IOrderWorkspaceData;
  };
  [Types.KDS]: {
    kds?: IOrderWorkspaceKDSData;
  };
};

const initialState: WorkspaceStateType = {
  workspaces: undefined,
  kds: undefined,
  loading: true,
  kdsLoading: true,
};
type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: WorkspaceStateType, action: ActionsType) => {
  if (action.type === Types.WORKSPACE) {
    return {
      ...state,
      loading: false,
      workspaces: action.payload.workspaces,
    };
  }
  if (action.type === Types.KDS) {
    return {
      ...state,
      kdsLoading: false,
      kds: action.payload.kds,
    };
  }

  return state;
};

type Props = {
  children: React.ReactNode;
};

export function WorkspaceProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onGetWorkspaces = useCallback(async () => {
    try {
      const res = await getWorkspaces();

      dispatch({
        type: Types.WORKSPACE,
        payload: {
          workspaces: res?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: Types.WORKSPACE,
        payload: {
          workspaces: undefined,
        },
      });
    }
  }, []);

  const onGetWorkspaceKDSs = useCallback(async () => {
    try {
      const res = await getKDSs();

      dispatch({
        type: Types.KDS,
        payload: {
          kds: res?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: Types.KDS,
        payload: {
          kds: undefined,
        },
      });
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      workspaces: state.workspaces,
      kds: state.kds,
      loading: state.loading,
      kdsLoading: state.kdsLoading,
      onGetWorkspaces,
      onGetWorkspaceKDSs,
    }),
    [
      onGetWorkspaces,
      onGetWorkspaceKDSs,
      state.workspaces,
      state.kds,
      state.loading,
      state.kdsLoading,
    ]
  );

  return <WorkspaceContext.Provider value={memoizedValue}>{children}</WorkspaceContext.Provider>;
}
