import useSWR from 'swr';
import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { ICheckoutItemRequestBody } from '../types/checkout';
import axiosInstance, { endpoints, fetcher } from '../utils/axios';
import {
  IOrderHistoriesData,
  IOrderHistory,
  IOrderHistoryData,
  IOrderWorkspaceData,
  IOrderWorkspaceKDSData,
} from '../types/order';

export async function createOrder(data: {
  items: ICheckoutItemRequestBody[];
}): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.order.createOrder;

  const res = await axiosInstance.post(URL, data);

  return res;
}

export function useGetWorkspace() {
  const URL = [`${endpoints.orderWorkspace.list}`];
  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      workSpaceOrders: data as IOrderWorkspaceData,
      workSpaceOrdersLoading: isLoading,
      workSpaceOrdersError: error,
      workSpaceOrdersValidating: isValidating,
      workSpaceOrdersEmpty: !isLoading && !data?.data,
      workSpaceOrdersMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetOrderHistories(page?: number, size?: number, date?: string | null) {
  const URL = [`${endpoints.orderHistory.list}`, { params: { page, size, date } }];

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      orderHistories: data as IOrderHistoriesData,
      orderHistoriesLoading: isLoading,
      orderHistoriesError: error,
      orderHistoriesValidating: isValidating,
      orderHistoriesEmpty: !isLoading && !data?.data.length,
      orderHistoriesMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function completeOrder(
  orderId: string
): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.order.complete;

  const res = await axiosInstance.post(URL, { orderId });

  return res;
}

export function useGetOrder(id: string) {
  const URL = endpoints.order.info;

  const { data, isLoading, error, isValidating, mutate } = useSWR(
    id ? `${URL}/${id}` : null,
    fetcher
  );

  const memoizedValue = useMemo(
    () => ({
      order: data as IOrderHistoryData,
      orderLoading: isLoading,
      orderError: error,
      orderValidating: isValidating,
      orderEmpty: !isLoading && !data?.data,
      orderMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function updateOrder(data: {
  orderId: string;
  items: ICheckoutItemRequestBody[];
}): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.order.updateOrder;

  const res = await axiosInstance.post(URL, data);

  return res;
}

export async function payLaterOrder(
  orderId: string
): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.order.payLater;

  const res = await axiosInstance.post(URL, { orderId });

  return res;
}

export async function cancelOrder(
  orderId: string
): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.order.cancelOrder;

  const res = await axiosInstance.post(URL, { orderId });

  return res;
}

export async function collectOrderTip(
  orderId: string,
  tipAmount: number
): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.order.updateTip;

  const res = await axiosInstance.put(URL, { orderId, tipAmount });

  return res;
}

export function useGetKitchenDisplay() {
  const URL = [`${endpoints.orderWorkspace.kds}`];
  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      kdsOrders: data as IOrderWorkspaceKDSData,
      kdsOrdersLoading: isLoading,
      kdsOrdersError: error,
      kdsOrdersValidating: isValidating,
      kdsOrdersEmpty: !isLoading && !data?.data,
      kdsOrdersMutate: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export async function prepareComplate(
  orderId: string
): Promise<AxiosResponse<{ data: IOrderHistory }>> {
  const URL = endpoints.orderWorkspace.prepareComplete;

  const res = await axiosInstance.post(URL, { orderId });

  return res;
}
