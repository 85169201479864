// routes
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const STORAGE_KEY = {
  POS_ACCESS_TOKEN: 'accessToken',
  POS_REFRESH_TOKEN: 'refreshToken',
};

export const STORAGE_KEY_FCM = 'fcmToken';

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setToken = (accessToken: string | null, refreshToken?: string) => {
  if (accessToken && refreshToken) {
    localStorage.setItem(STORAGE_KEY.POS_ACCESS_TOKEN, accessToken);
    localStorage.setItem(STORAGE_KEY.POS_REFRESH_TOKEN, refreshToken);
  } else {
    localStorage.removeItem(STORAGE_KEY.POS_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEY.POS_REFRESH_TOKEN);
  }
};
