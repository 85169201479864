// scrollbar
import 'simplebar-react/dist/simplebar.min.css';
// i18n
import 'src/locales/i18n';
// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// locale
import { LocalizationProvider } from 'src/locales';
import 'numeral/locales/th';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { SnackbarProvider } from 'src/components/snackbar';
import { ConfirmDialogContextProvider } from 'src/components/custom-dialog';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
// sections
import { CheckoutProvider } from './sections/checkout/context';
import { WorkspaceProvider } from './sections/workspace/context';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'red', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <CheckoutProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <WorkspaceProvider>
                      <ConfirmDialogContextProvider>
                        <Router />
                      </ConfirmDialogContextProvider>
                    </WorkspaceProvider>
                  </AuthConsumer>
                </CheckoutProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
