// @mui
import Stack from '@mui/material/Stack';
// components
import { AppBar, Toolbar } from '@mui/material';
import Logo from 'src/components/logo';
import { LanguagePopover } from '../_common';
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const renderLogo = (
    <Logo
      sx={{
        m: { xs: 2, md: 4 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        py: { xs: 15, md: 30 },
      }}
    >
      {children}
    </Stack>
  );

  const renderLanguage = (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={{ xs: 0.5, sm: 1 }}
    >
      <LanguagePopover />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      <AppBar
        sx={{
          height: HEADER.H_MOBILE,
        }}
      >
        <Toolbar
          sx={{
            height: 1,
          }}
        >
          {renderLogo}

          {renderLanguage}
        </Toolbar>
      </AppBar>

      {renderContent}
    </Stack>
  );
}
