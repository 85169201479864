import { createContext, useContext } from 'react';
// types
import { WorkspaceContextProps } from 'src/types/workspace';

// ----------------------------------------------------------------------

export const WorkspaceContext = createContext({} as WorkspaceContextProps);

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);

  if (!context) throw new Error('useWorkspaceContext must be use inside WorkspaceProvider');

  return context;
};
