import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'p';

  return date ? format(new Date(date), fm) : '';
}

// ref: https://ralzohairi.medium.com/displaying-dynamic-elapsed-time-in-javascript-260fa0e95049
export function getElapsedTime(startTime: Date, endTime: Date) {
  let timeDiff = endTime.getTime() - startTime.getTime();
  timeDiff /= 1000;

  // Extract integer seconds that do not form a minute using %
  const seconds = Math.floor(timeDiff % 60);
  // Pad seconds with a zero (if necessary) and convert to string
  const secondsAsString = seconds < 10 ? `0${seconds}` : seconds;

  timeDiff = Math.floor(timeDiff / 60);

  // Extract integer minutes that don't form an hour using %
  const minutes = timeDiff % 60;
  // Pad minutes with a zero (if necessary) and convert to string
  const minutesAsString = minutes < 10 ? `0${minutes}` : minutes;

  // Convert time difference from minutes to hours
  timeDiff = Math.floor(timeDiff / 60);

  // Extract integer hours that don't form a day using %
  const hours = timeDiff % 24;
  // Convert time difference from hours to days
  timeDiff = Math.floor(timeDiff / 24);

  // The rest of timeDiff is number of days
  const days = timeDiff;
  const totalHours = hours + days * 24; // add days to hours
  const totalHoursAsString = totalHours < 10 ? `0${totalHours}` : totalHours;

  if (totalHoursAsString === '00') {
    return `${minutesAsString}:${secondsAsString}`;
  }
  return `${totalHoursAsString}:${minutesAsString}:${secondsAsString}`;
}
