// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    menus: `${ROOTS.DASHBOARD}/menus`,
    orderHistory: `${ROOTS.DASHBOARD}/order-history`,
    details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
    workspace: `${ROOTS.DASHBOARD}/workspace`,
    settings: {
      root: `${ROOTS.DASHBOARD}/setting`,
      printer: `${ROOTS.DASHBOARD}/setting/printer`,
      terminal: `${ROOTS.DASHBOARD}/setting/terminal`,
    },
  },
};
