import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { STORAGE_KEY, setToken } from 'src/auth/context/jwt/utils';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

const posInstance = () => {
  const axiosInstance = axios.create({ baseURL: HOST_API });

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      const accessToken = localStorage.getItem(STORAGE_KEY.POS_ACCESS_TOKEN);

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const ogConfig = error.config;
      const refresh = localStorage.getItem(STORAGE_KEY.POS_REFRESH_TOKEN);

      if (error.response.status === 403 && !ogConfig._retry) {
        ogConfig._retry = true;

        try {
          const result = await axios.get(`${HOST_API}${endpoints.auth.refreshToken}`, {
            headers: { Authorization: `Bearer ${refresh}` },
          });

          const { data } = result.data;
          if (data.accessToken && data.refreshToken) {
            setToken(data.accessToken, data.refreshToken);
          }

          return await axiosInstance(ogConfig);
        } catch (e) {
          setToken(null);

          window.location.href = paths.page403;

          return e;
        }
      }
      return Promise.reject(error?.response?.data);
    }
  );

  return axiosInstance;
};

const axiosInstance = posInstance();
export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    requestQr: '/pos/device/sign-in/request/POS',
    confirmLogin: '/pos/device/sign-in/confirm',
    me: '/pos/device/me',
    refreshToken: '/pos/device/session/refresh',
    updateNotificationSettings: '/pos/device/update-notification-setting',
  },

  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/pos/branch/item',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  order: {
    createOrder: '/order/pos/new',
    complete: '/order/pos/complete',
    info: '/order/pos/info',
    updateOrder: '/order/pos/update',
    payLater: '/order/pos/pay-later',
    cancelOrder: '/order/pos/cancel',
    updateTip: '/order/pos/update-tip',
  },
  orderWorkspace: {
    list: '/order/pos/workspace',
    kds: '/order/pos/kitchen-workspace',
    prepareComplete: '/order/pos/preparation-complete',
  },
  orderHistory: {
    list: '/order/pos/history',
  },
  checkout: {
    pay: '/order/pos/pay',
  },
  stripe: {
    connectionToken: '/pos/device/stripe-connection-token',
    paymentIntent: '/order/pos/payment-intent',
  },
  terminal: {
    list: '/pos/device/terminal/me',
    create: '/pos/device/terminal/new',
    updateTitle: '/pos/device/terminal/update-title',
    remove: '/pos/device/terminal/remove',
  },
  category: {
    list: '/pos/item/category/business',
  },
};
