import { useEffect, useMemo, useCallback, useState } from 'react';
import {
  CreatePaymentMethodPromptPayData,
  Stripe as StripeType,
  loadStripe,
} from '@stripe/stripe-js';
// hooks
import { isEqual, sumBy } from 'lodash';
import {
  DiscoverResult,
  ErrorResponse,
  IPaymentIntent,
  ISdkManagedPaymentIntent,
  Reader,
  Terminal,
  loadStripeTerminal,
} from '@stripe/terminal-js';
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// _mock
import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
// types
import { ICheckoutItem, ICheckoutSelectPayment } from 'src/types/checkout';
// api
import { createOrder, updateOrder } from 'src/api/order';
import { createStripePaymentIntent, getStripeConnectionToken } from 'src/api/stripe';
//
import { useAuthContext } from 'src/auth/hooks';
import { CheckoutContext } from './checkout-context';
import { STRIPE_API_KEY, STRIPE_SIMULATE_TERMINAL } from '../../../config-global';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'checkout';

const initialState = {
  activeStep: 0,
  items: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  shipping: 0,
  billing: null,
  totalItems: 0,
};

type Props = {
  children: React.ReactNode;
};

export function CheckoutProvider({ children }: Props) {
  const router = useRouter();

  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);
  const { user } = useAuthContext();
  const [terminalInstance, setTerminalInstance] = useState<Terminal>();
  const [stripeInstance, setStripeInstance] = useState<StripeType | null>(null);

  // -------------------Terminal-----------------------------

  const onConnectStripe = useCallback(async () => {
    const stripeAcc = user?.branch?.bankAccount?.stripeAccountId;

    if (!STRIPE_API_KEY || !stripeAcc) return;

    const initStripe: StripeType | null = await loadStripe(STRIPE_API_KEY, {
      stripeAccount: stripeAcc,
    });

    setStripeInstance(initStripe);
  }, [user?.branch]);

  const onConnectStripeTerminal = useCallback(async () => {
    const StripeTerminal = await loadStripeTerminal();
    const terminal = await StripeTerminal?.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });

    setTerminalInstance(terminal);
    connectReaderHandler(terminal);
  }, []);

  useEffect(() => {
    if (user && user?.branch?.bankAccount?.isProfileCompleted) {
      onConnectStripeTerminal();
      onConnectStripe();
    }
  }, [onConnectStripeTerminal, user, onConnectStripe]);

  async function fetchConnectionToken() {
    const res = await getStripeConnectionToken();
    return res.data.data;
  }

  function unexpectedDisconnect() {
    // You might want to display UI to notify the user and start re-discovering readers
  }

  const connectReaderHandler = async (terminal: Terminal | undefined) => {
    const config = { simulated: STRIPE_SIMULATE_TERMINAL };
    const discoverResult: (Partial<DiscoverResult> & Partial<ErrorResponse>) | undefined =
      await terminal?.discoverReaders(config);

    if (discoverResult?.error) {
      console.log('Failed to discover: ', discoverResult?.error);
    } else if (discoverResult?.discoveredReaders?.length === 0) {
      console.log('No available readers.');
    } else {
      // Just select the first reader here.
      const selectedReader =
        discoverResult?.discoveredReaders && discoverResult?.discoveredReaders[0];

      const connectResult:
        | (Partial<ErrorResponse> &
            Partial<{
              reader: Reader;
            }>)
        | undefined = await terminal?.connectReader(selectedReader as Reader);
      if (connectResult?.error) {
        console.log('Failed to connect: ', connectResult?.error);
      } else {
        console.log('Connected to reader: ', connectResult?.reader?.label);
      }
    }
  };

  const fetchPaymentIntentClientSecret = useCallback(async (id: string, methods: string[]) => {
    const res = await createStripePaymentIntent(id, methods);

    return res.data.data.intent_secret;
  }, []);

  const collectPayment = useCallback(
    async (id: string) => {
      const client_secret = await fetchPaymentIntentClientSecret(id, ['card_present']);

      terminalInstance?.setSimulatorConfiguration({ testCardNumber: '4000000000000077' });

      // collect payment
      const result:
        | Partial<
            ErrorResponse & {
              paymentIntent: ISdkManagedPaymentIntent;
            }
          >
        | undefined = await terminalInstance?.collectPaymentMethod(client_secret);

      if (result?.error) {
        // Placeholder for handling result.error
        throw new Error(result?.error?.code || 'Incomplete');
      }
      // process payment
      const response:
        | Partial<
            ErrorResponse & {
              paymentIntent: IPaymentIntent;
            }
          >
        | undefined =
        result?.paymentIntent && (await terminalInstance?.processPayment(result?.paymentIntent));
      if (response?.error) {
        console.log(response.error);
        throw new Error(response?.error.code || 'Incomplete');
      }
      if (response?.paymentIntent) {
        switch (response.paymentIntent.status) {
          case 'succeeded':
            return 'Payment succeeded!';
          case 'processing':
            return 'Your payment is processing.';
          case 'requires_capture':
            return 'Payment succeeded!';
          case 'requires_payment_method':
            return 'Your payment was not successful, please try again.';
          default:
            return 'Something went wrong.';
        }
      }

      return result;
    },
    [terminalInstance, fetchPaymentIntentClientSecret]
  );

  const collectPaymentPromptPay = useCallback(
    async (id: string) => {
      if (!stripeInstance) {
        throw new Error('Connect to stripe failed!');
      }
      const client_secret = await fetchPaymentIntentClientSecret(id, ['promptpay']);

      const res = await stripeInstance?.confirmPromptPayPayment(client_secret, {
        payment_method: {
          type: 'promptpay',
          billing_details: {
            email: 'example@example.com',
          },
        } as CreatePaymentMethodPromptPayData,
      });

      // Stripe.js will open a modal to display the PromptPay QR Code to your customer

      if (res?.paymentIntent?.status === 'succeeded') {
        // The user scanned the QR code
        return 'Payment succeeded';
      }
      // The user closed the modal, cancelling payment
      throw new Error(res.paymentIntent?.status || 'Incomplete');
    },
    [fetchPaymentIntentClientSecret, stripeInstance]
  );
  // -------------------Terminal-----------------------------

  const onGetCart = useCallback(() => {
    const totalItems: number = state.items.reduce(
      (total: number, item: ICheckoutItem) => total + item.quantity,
      0
    );

    const subTotal: number = state.items.reduce((total: number, item: ICheckoutItem) => {
      const optionPrice = item?.options
        ? sumBy(item.options, (option) => sumBy(option.selectedChoices, 'price'))
        : 0;

      return total + item.quantity * (item.price + optionPrice);
    }, 0);

    update('subTotal', subTotal);
    update('totalItems', totalItems);
    update('billing', state.activeStep === 1 ? null : state.billing);
    update('discount', state.items.length ? state.discount : 0);
    update('shipping', state.items.length ? state.shipping : 0);
    update('total', state.subTotal - state.discount + state.shipping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.items,
    state.activeStep,
    state.billing,
    state.discount,
    state.shipping,
    state.subTotal,
  ]);

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);

    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  const handleCheckExisting = (item: ICheckoutItem, newItem: ICheckoutItem) =>
    item.managementBranchItemId === newItem.managementBranchItemId &&
    isEqual(item.options, newItem.options) &&
    isEqual(item.notes, newItem.notes);

  const onAddToCart = useCallback(
    (newItem: ICheckoutItem) => {
      const updatedItems: ICheckoutItem[] = state.items.map((item: ICheckoutItem) => {
        if (handleCheckExisting(item, newItem)) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }

        return item;
      });

      if (!updatedItems.some((item: ICheckoutItem) => handleCheckExisting(item, newItem))) {
        updatedItems.push(newItem);
      }

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDeleteCart = useCallback(
    (index: number) => {
      // delete item in cart by index
      const updatedItems = [...state.items];
      updatedItems.splice(index, 1);

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onBackStep = useCallback(() => {
    update('activeStep', state.activeStep - 1);
  }, [update, state.activeStep]);

  const onNextStep = useCallback(() => {
    update('activeStep', state.activeStep + 1);
  }, [update, state.activeStep]);

  const onGotoStep = useCallback(
    (step: number) => {
      update('activeStep', step);
    },
    [update]
  );

  const onIncreaseQuantity = useCallback(
    (index: number) => {
      const updatedItems = state.items.map((item: ICheckoutItem, idx: number) => {
        if (idx === index) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }

        return item;
      });

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onDecreaseQuantity = useCallback(
    (index: number) => {
      const updatedItems = state.items.map((item: ICheckoutItem, idx: number) => {
        if (idx === index) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      });

      update('items', updatedItems);
    },
    [update, state.items]
  );

  const onCreateOrder = useCallback(async () => {
    const mappindItems = state.items.map((item: ICheckoutItem) => ({
      managementBranchItemId: item.managementBranchItemId,
      quantity: item.quantity,
      notes: item.notes,
      options: item?.options?.map((option) => ({
        itemOptionId: option.itemOptionId,
        selectedChoices: option?.selectedChoices?.map((choice) => ({
          itemOptionChoiceId: choice.itemOptionChoiceId,
        })),
      })),
    }));

    const res = await createOrder({ items: mappindItems });

    const { items, priceSummary } = res.data.data;
    update('order', res.data.data);

    update('subTotal', priceSummary.subTotal);
    update('totalItems', items.length);
    onNextStep();
  }, [onNextStep, state.items, update]);

  const onUpdateOrder = useCallback(
    async (orderId: string) => {
      const mappindItems = state.items.map((item: ICheckoutItem) => ({
        managementBranchItemId: item.managementBranchItemId,
        quantity: item.quantity,
        notes: item.notes,
        options: item?.options?.map((option) => ({
          itemOptionId: option.itemOptionId,
          selectedChoices: option?.selectedChoices?.map((choice) => ({
            itemOptionChoiceId: choice.itemOptionChoiceId,
          })),
        })),
      }));

      const res = await updateOrder({ orderId, items: mappindItems });

      const { items, priceSummary } = res.data.data;
      update('order', res.data.data);

      update('subTotal', priceSummary.subTotal);
      update('totalItems', items.length);
      onNextStep();
    },
    [onNextStep, state.items, update]
  );

  const onCreateBilling = useCallback(
    (address: any) => {
      update('billing', address);

      onNextStep();
    },
    [onNextStep, update]
  );

  const onApplyDiscount = useCallback(
    (discount: number) => {
      update('discount', discount);
    },
    [update]
  );

  const onApplyShipping = useCallback(
    (shipping: number) => {
      update('shipping', shipping);
    },
    [update]
  );

  const onSelectPayment = useCallback(
    (payment: ICheckoutSelectPayment) => {
      update('payment', payment);
    },
    [update]
  );

  const completed = state.activeStep === PRODUCT_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      reset();
      router.replace(paths.dashboard.root);
    }
    reset();
  }, [completed, reset, router]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      onAddToCart,
      onDeleteCart,
      //
      onIncreaseQuantity,
      onDecreaseQuantity,
      //
      onCreateOrder,
      onUpdateOrder,
      onCreateBilling,
      onApplyDiscount,
      onApplyShipping,
      onSelectPayment,
      collectPayment,
      collectPaymentPromptPay,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      onReset,
    }),
    [
      completed,
      onAddToCart,
      onApplyDiscount,
      onApplyShipping,
      onBackStep,
      onCreateOrder,
      onUpdateOrder,
      onCreateBilling,
      onDecreaseQuantity,
      onDeleteCart,
      onGotoStep,
      onIncreaseQuantity,
      onNextStep,
      onReset,
      state,
      onSelectPayment,
      collectPayment,
      collectPaymentPromptPay,
    ]
  );

  return <CheckoutContext.Provider value={memoizedValue}>{children}</CheckoutContext.Provider>;
}
