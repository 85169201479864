import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { FIREBASE_API } from '../config-global';
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  // ...
  apiKey: FIREBASE_API.apiKey,
  authDomain: FIREBASE_API.authDomain,
  projectId: FIREBASE_API.projectId,
  storageBucket: FIREBASE_API.storageBucket,
  messagingSenderId: FIREBASE_API.messagingSenderId,
  appId: FIREBASE_API.appId,
  measurementId: FIREBASE_API.measurementId,
};

const app = initializeApp(firebaseConfig);
export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log('Firebase is not supported in this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();
// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined') {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return await getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
        Promise.resolve(
          getToken(messagingResolve, {
            vapidKey: FIREBASE_API.validKey,
            serviceWorkerRegistration,
          })
        )
      );
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
  return undefined;
};
