import axiosInstance, { endpoints } from '../utils/axios';

export async function getStripeConnectionToken() {
  const URL = endpoints.stripe.connectionToken;

  const res = await axiosInstance.get(URL);
  return res;
}
export async function createStripePaymentIntent(orderId: string, paymentMethod: string[]) {
  const URL = endpoints.stripe.paymentIntent;

  const res = await axiosInstance.post(URL, { orderId, paymentMethod });

  return res;
}
