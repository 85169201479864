import { Button } from '@mui/material';
import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import ConfirmDialog from './confirm-dialog';
import { ConfirmDialogProps } from './types';

type ConfirmOptionProps = Partial<ConfirmDialogProps> & {
  onSubmit?: (pinCode?: string, reason?: string) => void;
};

const ConfirmationServiceContext = createContext<(options: ConfirmOptionProps) => void>(() => {});

export const useConfirm = () => {
  const context = useContext(ConfirmationServiceContext);
  if (context === undefined) {
    throw new Error(`useConfirm must be use within ConfirmDialogContextProvider`);
  }

  return context;
};

type Props = {
  children: ReactNode;
};

const ConfirmDialogContextProvider = ({ children }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<ConfirmOptionProps | null>(null);

  const openConfirmDialog = useCallback((options: ConfirmOptionProps) => {
    setDialogOpen(true);
    setDialogConfig({ ...options });
  }, []);

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  const handleClose = () => {
    resetDialog();
  };

  const handleConfirm = () => {
    dialogConfig?.onSubmit?.();
    resetDialog();
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmDialog} children={children} />
      {dialogOpen ? (
        <ConfirmDialog
          title={dialogConfig?.title || ''}
          open={dialogOpen}
          onClose={handleClose}
          action={
            <Button variant="contained" onClick={handleConfirm}>
              Yes
            </Button>
          }
          {...dialogConfig}
        />
      ) : null}
    </>
  );
};

export default ConfirmDialogContextProvider;
