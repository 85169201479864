import { useMemo } from 'react';
// routes
import { useTranslation } from 'react-i18next';
import { paths } from 'src/routes/paths';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const ICONS = {
  product: <Iconify icon="solar:bag-4-bold-duotone" width={24} />,
  history: <Iconify icon="solar:clock-circle-bold-duotone" width={24} />,
  workspace: <Iconify icon="solar:clipboard-text-bold-duotone" width={24} />,
  settings: <Iconify icon="solar:settings-bold-duotone" width={24} />,
};

// ----------------------------------------------------------------------

type Props = {
  branchName?: string;
};
export function useNavData(prop?: Props) {
  const { t } = useTranslation();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: `${prop?.branchName || 'POS'}`,
        items: [
          { title: t('menus'), path: paths.dashboard.root, icon: ICONS.product },
          { title: t('workspace'), path: paths.dashboard.workspace, icon: ICONS.workspace },
          { title: t('order_history'), path: paths.dashboard.orderHistory, icon: ICONS.history },
          // {
          //   title: t('settings'),
          //   path: paths.dashboard.settings.root,
          //   icon: ICONS.settings,
          //   children: [
          //     { title: t('printer'), path: paths.dashboard.settings.printer },
          //     { title: t('terminal'), path: paths.dashboard.settings.terminal },
          //   ],
          // },
        ],
      },
    ],
    [t, prop]
  );

  return data;
}
