import axiosInstance, { endpoints } from '../utils/axios';

export async function getUserMe() {
  const URL = endpoints.auth.me;

  const res = await axiosInstance.get(URL);
  return res;
}

export async function getRequestLogin() {
  const URL = endpoints.auth.requestQr;
  const response = await axiosInstance.get(URL);

  return response;
}

export async function reqConfirmLogin(data: {
  token: string;
  passcode: string;
  isNotificationEnable: boolean;
  notificationToken: string;
}) {
  const URL = endpoints.auth.confirmLogin;

  const res = await axiosInstance.post(URL, data);

  return res;
}

export async function updateNotificationSettings(data: {
  isNotificationEnable: boolean;
  fcmNotificationToken: string;
}) {
  const URL = endpoints.auth.updateNotificationSettings;

  const res = await axiosInstance.put(URL, data);

  return res;
}
