import { IOrderWorkspaceData, IOrderWorkspaceKDSData } from '../types/order';
import axiosInstance, { endpoints } from '../utils/axios';

export async function getWorkspaces() {
  const URL = endpoints.orderWorkspace.list;

  const res = await axiosInstance.get<IOrderWorkspaceData>(URL);

  return res;
}

export async function getKDSs() {
  const URL = endpoints.orderWorkspace.kds;

  const res = await axiosInstance.get<IOrderWorkspaceKDSData>(URL);

  return res;
}
